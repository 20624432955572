/* eslint-disable no-underscore-dangle */
function execute(sql, values, callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  return new Promise((resolve) => {
    self.ready().then(() => {
      this._dbInfo.db.transaction((tx) => {
        tx.executeSql(sql, values, (_tx, result) => {
          callback(result, resolve);
        });
      });
    });
  });
}

function readResult(result, index) {
  const { rows } = result;

  return rows.length >= index + 1 ? rows.item(index) : { value: "" };
}

function executeCallback(promise, callback) {
  if (callback) {
    promise.then(
      (result) => callback(null, result),
      (error) => callback(error)
    );
  }
}

function initStorage(options) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  const dbInfo = {
    db: null,
  };

  if (options) {
    Object.keys(options).forEach((key) => {
      dbInfo[key] =
        typeof options[key] !== "string"
          ? options[key].toString()
          : options[key];
    });
  }

  dbInfo.storeName = options.storeName || options.name;

  return new Promise((resolve) => {
    dbInfo.db = window.sqlitePlugin.openDatabase({
      name: "streem.db",
      location: "default",
      androidDatabaseProvider: "system",
    });

    dbInfo.db.transaction(
      (tx) => {
        tx.executeSql(
          `CREATE TABLE IF NOT EXISTS ${dbInfo.storeName}
          (id INTEGER PRIMARY KEY, key unique, value);`
        );
      },
      () => {},
      () => {
        self._dbInfo = dbInfo;
        self._execute = execute;
        resolve();
      }
    );
  });
}

function getItem(key, callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `SELECT value FROM ${self._dbInfo.storeName} WHERE key = ?;'`;

  const promise = self._execute(query, [key], (result, done) =>
    done(readResult(result, 0).value)
  );

  executeCallback(promise, callback);

  return promise;
}

function setItem(key, value, callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `REPLACE INTO ${self._dbInfo.storeName} (key, value) VALUES (?, ?)`;

  const promise = self._execute(query, [key, value], (_result, done) => done());

  executeCallback(promise, callback);

  return promise;
}

function removeItem(key, callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `DELETE FROM ${self._dbInfo.storeName} WHERE key = ?;`;

  const promise = self._execute(query, [key], (_result, done) => done());

  executeCallback(promise, callback);

  return promise;
}

function iterate(iterator, callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `SELECT * FROM ${self._dbInfo.storeName};`;

  const promise = self._execute(query, [], (result, done) => {
    for (let index = 0; index < result.rows.length; index += 1) {
      const row = readResult(result, index);
      iterator(row.value, row.key);
    }

    done();
  });

  executeCallback(promise, callback);

  return promise;
}

function clear(callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `DELETE FROM ${self._dbInfo.storeName}`;

  const promise = self._execute(query, [], (_result, done) => done());

  executeCallback(promise, callback);

  return promise;
}

function getKey(index, callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `SELECT key FROM ${self._dbInfo.storeName} WHERE id = ? LIMIT 1`;

  const promise = self._execute(query, [index + 1], (result, done) =>
    done(readResult(result, 0).key)
  );

  executeCallback(promise, callback);

  return promise;
}

function getKeys(callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `SELECT key FROM ${self._dbInfo.storeName}`;

  const promise = self._execute(query, [], (result, done) => {
    const keys = [];

    for (let index = 0; index < result.rows.length; index += 1) {
      keys.push(readResult(result, index).key);
    }

    done(keys);
  });

  executeCallback(promise, callback);

  return promise;
}

function length(callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const query = `SELECT COUNT(key) as c FROM ${self._dbInfo.storeName}`;

  const promise = self._execute(query, [], (result, done) =>
    done(readResult(result, 0).c)
  );

  executeCallback(promise, callback);

  return promise;
}

function supported() {
  return new Promise((resolve) => {
    if (!window.cordova) {
      resolve(false);
    } else {
      document.addEventListener("deviceready", () => {
        const isSupported =
          typeof window?.sqlitePlugin?.openDatabase === "function";

        resolve(isSupported);
      });
    }
  });
}

export default {
  _driver: "CordovaSQLiteDriver",
  _initStorage: initStorage,
  _support: supported,
  getItem,
  iterate,
  removeItem,
  setItem,
  clear,
  key: getKey,
  keys: getKeys,
  length,
};
