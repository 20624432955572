<template>
  <Popper
    v-model="visible"
    caret
    class="c-social-amplify tw-text-left"
    click-outside-capture
    :offset="[0, 12]"
    placement="right-start"
    :root-element="rootElement"
    tether
  >
    <div
      class="c-social-amplify__arrow"
      data-popper-arrow
    />
    <div
      class="c-social-amplify__container q-pa-md"
      @click.stop.prevent
    >
      <div class="c-social-amplify__title">
        <h5>{{ $t("social_amplify_popup.social_amplify") }}</h5>

        <BaseButton
          color="white"
          flat
          icon="ion-close"
          icon-size="24px"
          padding="none sm"
          @click="visible = false"
        />
      </div>

      <div class="c-social-amplify__total">
        <p>
          {{
            $t("social_amplify_popup.total_interactions", {
              total: total.toLocaleString(),
            })
          }}
        </p>
      </div>

      <div class="c-social-amplify__widget">
        <h6>
          {{ $t("social_amplify_popup.trend_with_date", { dateRangeLabel }) }}
        </h6>
        <p>
          {{ $t("social_amplify_popup.realtime_social_amplification_subtext") }}
        </p>

        <div class="chart-container">
          <Chart
            class="q-mt-md c-social-amplify__widget--trend"
            :container-style="{ width: '350px', height: '160px' }"
            :data="trendProps.data"
            :options="trendProps.options"
            type="line"
          />
        </div>
      </div>

      <div class="c-social-amplify__widget">
        <h6>
          {{ $t("social_amplify_popup.amplification_by_platform_title") }}
        </h6>
        <p>
          {{ $t("social_amplify_popup.amplification_by_platform_subtext") }}
        </p>

        <div class="chart-container">
          <Chart
            class="q-mt-sm c-social-amplify__widget--amplification"
            :container-style="{ width: '145px', height: '145px' }"
            :data="amplificationProps.data"
            :options="amplificationProps.options"
            type="polarArea"
          />

          <Legend
            class="q-ml-sm"
            :items="amplificationProps.legend"
            layout="column"
          />
        </div>
      </div>
    </div>
  </Popper>
</template>

<script>
import { groupBy, sum } from "lodash-es";
import { storeToRefs } from "pinia";

import { BaseButton, Popper } from "shared/components/base";
import useSocialAmplify from "shared/composables/useSocialAmplify";
import {
  dateInTimeZone,
  shortTimezone,
  toMilliseconds,
} from "shared/helpers/date";
import { useGlobalStore } from "shared/stores/global";

export default {
  name: "SocialAmplifyPopup",
  components: {
    BaseButton,
    Popper,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    mention: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    rootElement: {
      type: HTMLElement,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const { interval, range } = useSocialAmplify(props);

    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    return {
      interval,
      range,
      appConfig,
    };
  },
  data() {
    return {
      platformsByType: {
        tweet: "Twitter",
        tweet_engagement_count: "Twitter",
        facebook_like: "Facebook",
        facebook_comment: "Facebook",
        facebook_share: "Facebook",
        facebook_post: "Facebook",
        facebook_haha_count: "Facebook",
        facebook_love_count: "Facebook",
        facebook_wow_count: "Facebook",
        facebook_sad_count: "Facebook",
        reddit_share: "Reddit",
        reddit_post: "Reddit",
        reddit_post_comment: "Reddit",
        reddit_engagement_value: "Reddit",
      },
      colors: {
        Twitter: {
          backgroundColor: "rgba(101, 180, 236, 0.8)",
          legend: "#69C0FF",
        },
        Facebook: {
          backgroundColor: "rgba(47, 84, 235, 0.8)",
          legend: "#2F54EB",
        },
        Reddit: {
          backgroundColor: "rgba(255, 111, 69, 0.8)",
          legend: "#FF6F45",
        },
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isCisionOne() {
      return this.appConfig.platformName === "CisionOne";
    },
    beforeFormatted() {
      return dateInTimeZone(this.range.before, "d MMM", this.timezone);
    },
    afterFormatted() {
      return dateInTimeZone(this.range.after, "d MMM", this.timezone);
    },
    timezone() {
      return this.mention.source.time_zone;
    },
    timezoneFormatted() {
      return shortTimezone(new Date(), this.mention.source.time_zone);
    },
    dateRangeLabel() {
      const after = new Date(toMilliseconds(this.range.after));
      const before = new Date(toMilliseconds(this.range.before));

      if (after.getDate() === before.getDate()) {
        return `${this.beforeFormatted}, ${this.timezoneFormatted}`;
      }

      if (after.getMonth() === before.getMonth()) {
        return `${after.getDate()} - ${this.beforeFormatted}, ${
          this.timezoneFormatted
        }`;
      }

      return `${this.afterFormatted} - ${this.beforeFormatted}, ${this.timezoneFormatted}`;
    },
    dateFormatter() {
      const { timezone, interval } = this;

      const format = {
        "15m": "h:mmaaa",
        "1h": "haaa",
        "1d": "d LLL",
      }[interval];

      return function formatTicks(value) {
        const date = new Date(value);
        let dateFormat = format;

        if (date.getHours() === 0 && interval !== "1d") {
          dateFormat = `d LLL - ${dateFormat}`;
        }

        return dateInTimeZone(new Date(date), dateFormat, timezone);
      };
    },
    trendProps() {
      const { dateFormatter, tooltipDateFormatter } = this;

      const labels = this.data[0][2].buckets.map(
        (bucket) => new Date(bucket.key_as_string)
      );

      const data = new Array(labels.length).fill(0);

      this.data.forEach((dataSet) => {
        dataSet[2].buckets.forEach((bucket, index) => {
          data[index] += bucket.total_engagement.value;
        });
      });

      const chartColour = this.isCisionOne ? "#fff" : "#D3ADF7";

      return {
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: ["#fff"],
              borderWidth: "5",
              borderColor: ["#fff"],
              radius: 0,
              pointHitRadius: 0,
              fill: false,
            },
          ],
        },
        options: {
          layout: {
            padding: 8,
          },
          scales: {
            x: {
              ticks: {
                color: chartColour,
                callback(value) {
                  return dateFormatter(this.getLabelForValue(value));
                },
              },
              grid: {
                zeroLineColor: chartColour,
                color: chartColour,
              },
            },
            y: {
              ticks: {
                color: chartColour,
              },
              grid: {
                zeroLineColor: chartColour,
                color: chartColour,
              },
            },
          },
          plugins: {
            clickevents: false,
            tooltip: {
              displayColors: false,
              callbacks: {
                title(tooltipItem) {
                  return tooltipDateFormatter(
                    dateFormatter(tooltipItem[0].label),
                    tooltipItem[0].raw
                  );
                },
              },
            },
          },
        },
      };
    },
    relevantPlatforms() {
      return ["Twitter", "Facebook", "Reddit"];
    },
    amplificationData() {
      const data = [];
      const backgroundColor = [];
      const legend = [];

      this.relevantPlatforms.forEach((platform) => {
        const buckets = this.bucketsByPlatform[platform] || [];
        const count = this.getBucketTotalEngagement(buckets);

        data.push(count);
        backgroundColor.push(this.colors[platform].backgroundColor);

        legend.push({
          color: this.colors[platform].legend,
          label: `${platform}: ${count} (${this.percentage(
            count,
            this.totalCount
          )}%)`,
        });
      });

      return {
        data,
        backgroundColor,
        legend,
      };
    },
    amplificationProps() {
      const { data, backgroundColor, legend } = this.amplificationData;

      const chartColour = this.isCisionOne ? "#fff" : "#D3ADF7";

      return {
        data: {
          datasets: [
            {
              data,
              backgroundColor,
              borderWidth: 0,
            },
          ],
          labels: this.relevantPlatforms,
        },
        options: {
          maintainAspectRatio: true,
          responsive: true,
          layout: {
            padding: 4,
          },
          plugins: {
            clickevents: false,
            tooltip: {
              enabled: false,
              external: null,
            },
          },
          scales: {
            r: {
              grid: {
                color: chartColour,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
        legend,
      };
    },
    totalCount() {
      return this.getBucketTotalEngagement(this.data);
    },
    bucketsByPlatform() {
      return groupBy(this.data, (bucket) => this.platformsByType[bucket.key]);
    },
  },
  methods: {
    tooltipDateFormatter(date, value) {
      return `${date}: ${value}`;
    },
    getBucketTotalEngagement(buckets) {
      const values = [];

      buckets.forEach((bucket) => {
        bucket["2"].buckets.forEach((histogramBucket) => {
          values.push(histogramBucket.total_engagement.value);
        });
      });

      return sum(values);
    },
    percentage(point, total) {
      return Math.round((point / total || 0) * 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-social-amplify {
  &__container {
    background: var(--s-amplify-bg);
    border-radius: 6px;
    width: 350px;
    z-index: 99999;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h5 {
      font-family: $typography-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 100%;
      color: $white;
      margin: 0;
    }
  }

  &__total {
    margin: 8px 0 0;

    p {
      font-family: $typography-font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      color: $white;
      margin: 0;
    }
  }

  &__widget {
    margin: 20px 0;

    h6 {
      font-family: $typography-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      color: $white;
      margin: 0 0 8px;
    }

    p {
      font-family: $typography-font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      color: $white;
      margin: 0;
    }

    .chart-container {
      display: flex;
      flex-direction: row;

      :deep(.c-legend-item__label) {
        color: $white;
      }
    }

    &--amplification {
      overflow: hidden;
    }
  }

  &__arrow {
    position: relative;
    background-color: var(--s-amplify-bg);
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866) !important;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: inherit;
    }

    &,
    &::before,
    &::after {
      width: 18px;
      height: 18px;
      border-top-right-radius: 30%;
    }

    &::before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%) !important;
    }

    &::after {
      transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%) !important;
    }
  }

  &[data-popper-placement^="left"] > &__arrow {
    right: -12px;
    transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866) !important;
  }

  &[data-popper-placement^="right"] > &__arrow {
    left: -12px;
    transform: rotate(-30deg) skewX(-30deg) scale(1, 0.866) !important;
  }

  &[data-popper-placement$="start"] > &__arrow {
    top: 45px !important;
  }

  &[data-popper-placement$="end"] > &__arrow {
    top: calc(100% - 67.5px) !important;
  }
}
</style>
