<template>
  <div
    v-intersection="handleIntersection"
    class="full-width"
  >
    <MentionCard
      ref="mentionCard"
      :action-sheet-component="actionSheetComponent"
      :always-show-keywords="alwaysShowKeywords"
      class="tw-cursor-pointer"
      :in-mention-preview-alert="inMentionPreviewAlert"
      :list-title="listTitle"
      :logo="mention.source ? mention.source.logo_url : null"
      :mention="mention"
      :mentions="mentions"
      :no-highlighting="noHighlighting"
      :options="options"
      :selected="selected"
      :show-checkbox="showCheckbox"
      :stream="stream"
      :title="mention.title"
      @click="mentionClicked($event)"
      @mention-removed="removeMention"
      @mention-selected="$emit('mention-selected', $event)"
    >
      <template #header>
        <SyndicationsPopper
          v-if="mention?.syndications?.length"
          v-slot="{ toggleOpen }"
          :mention="mention"
          :parent-el-ref="$refs.mentionCard?.$el"
          :stream="stream"
          @syndication-clicked="mentionClicked($event)"
        >
          <BaseButton
            new-design
            plain
            @click.stop.prevent="toggleOpen"
          >
            <LineClamp
              class="tw-text-base tw-font-bold tw-text-denim-950"
              :lines="1"
              tag="span"
            >
              {{ mention.source.name }}
              <template #after="{ clamped }">
                <StreemTooltip v-if="clamped">{{
                  mention.source.name
                }}</StreemTooltip>
              </template>
            </LineClamp>
            <span>+{{ mention.syndications.length }}</span>
          </BaseButton>
        </SyndicationsPopper>
        <span
          v-else
          class="alternate"
        >
          <LineClamp
            class="tw-text-base tw-font-bold tw-text-denim-950"
            :lines="1"
            tag="span"
          >
            {{ mention.source.name }}
            <template #after="{ clamped }">
              <StreemTooltip v-if="clamped">{{
                mention.source.name
              }}</StreemTooltip>
            </template>
          </LineClamp>
        </span>

        <span class="tw-text-denim-900">
          {{ mention.source.location }}
        </span>

        <span class="softer tw-text-sm">
          <TimeAgo
            :date="mention.timestamp"
            :timezone="mention.source.time_zone"
          />
          <span v-if="mention.program_airing">
            · {{ mention.program_airing.name }}
          </span>
        </span>
        <ArchivedContentLabel
          v-if="isTveyesContent && isArchived"
          :mention="mention"
        />
      </template>

      <slot />

      <template #list-title-after>
        <div
          v-if="isTveyesContent && isArchived"
          class="tw-mb-1"
        >
          <ArchivedContentLabel :mention="mention" />
        </div>
      </template>

      <template #beforeBody>
        <div
          v-if="mention.thumbnails && mention.thumbnails.length"
          @click.stop
        >
          <!-- negative margin below to force the player in this card to be 100% width -->
          <div
            v-if="clip.media_url"
            :class="isReportsRoute ? 'tw-mb-4' : '-tw-mx-4 tw-mb-4'"
          >
            <UniversalPlayerControlWidget
              v-if="isReportsRoute"
              :clip="clip"
              new-design
              @play="playInPopout"
            />

            <PlayerPopoutTVCardControl
              v-else
              v-show="!hasPortalContent"
              :clip="clip"
              :preview-image-url="mention.thumbnails[0].url"
              @play="playInPopout"
            />

            <PortalTarget
              :name="portalName"
              @change="handlePortalChange"
            />
          </div>
        </div>
      </template>

      <template #list-body-after>
        <div
          v-if="isTveyesContent && isArchived"
          @click.stop
        >
          <div class="tw-mt-2">
            <FullBroadcastAnchor
              font-size="xs"
              :mention="mention"
            />
          </div>
        </div>

        <div
          v-if="isTveyesContent && isExpired"
          class="tw-mt-2"
        >
          <ExpiredContentNotice />
        </div>
      </template>

      <template #afterBody>
        <div @click.stop>
          <div
            v-if="$isDesktop && hasTranscript"
            class="tw-mt-2 tw-cursor-pointer tw-text-denim-600 hover:tw-text-denim-900"
            @click="openViewTranscriptModal"
          >
            {{ $t("tv_mention_card.view_transcript") }}
          </div>

          <div
            v-if="isTveyesContent && isArchived"
            class="tw-mt-4"
          >
            <FullBroadcastAnchor
              font-size="md"
              :mention="mention"
            />
          </div>

          <div
            v-if="isTveyesContent && isExpired"
            class="tw-mt-4"
          >
            <ExpiredContentNotice />
          </div>

          <MentionCardInsights
            :visible-widgets="visibleWidgets"
            :widget-keys="[sentimentWidgetKey]"
          >
            <template #components>
              <BroadcastAudienceWidget
                dense
                medium="tv"
                :mention="mention"
              />

              <AdvertisingValueWidget
                dense
                :mention="mention"
              />

              <SentimentWidget
                :key="sentimentWidgetKey"
                dense
                :mention="mention"
                :stream="stream"
              />

              <ReactScoreWidget
                dense
                :is-parent-visible="isIntersecting"
                :mention="mention"
              />

              <ImpactScoreWidget
                dense
                :impact-score="mention.impact_score"
              />
            </template>
          </MentionCardInsights>
        </div>
      </template>

      <template #after>
        <!-- Syndication for Captions and Tv Super -->
        <div
          v-if="mention.similarMentions && mention.similarMentions.length"
          class="card syndication-card pointer hide-radius-top"
        >
          <div
            v-for="(syndication, key) in mention.similarMentions"
            :key="key"
            class="card-caption mention-content hide-radius-top"
            :class="'border-color-' + medium"
          >
            <div
              class="row no-wrap items-center q-py-smd"
              @click="syndicationClicked(syndication)"
            >
              <span class="bold q-mr-xs"> {{ syndication.source.name }} </span
              >&nbsp;
              <span>
                {{ syndication.source.location }}
                {{
                  syndication.program_airing
                    ? " - " + syndication.program_airing.name
                    : ""
                }}
              </span>
              <span class="muted syndication-time q-pr-smd">
                <TimeAgo
                  :date="syndication.start_time"
                  :timezone="mention.time_zone"
                />
              </span>
            </div>
          </div>
        </div>
      </template>

      <template #list-actions>
        <UniversalPlayerControlWidget
          v-if="!mention.datahub && clip.media_url"
          :clip="clip"
          @play="playInPopout"
        />
      </template>
    </MentionCard>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import { BaseButton, LineClamp, StreemTooltip } from "shared/components/base";
import BroadcastAudienceWidget from "shared/components/BroadcastAudienceWidget.vue";
import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import ImpactScoreWidget from "shared/components/ImpactScoreWidget.vue";
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import PlayerPopoutTVCardControl from "shared/components/players/PlayerPopoutTVCardControl.vue";
import { UniversalPlayerControlWidget } from "shared/components/players/UniversalPlayerControlWidget";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import { SyndicationsPopper } from "shared/components/SyndicationsPopper";
import useMention from "shared/composables/useMention";
import { usePortalContent } from "shared/composables/usePortalContent";
import { NEXT_CLIP_OFFSET, POPOUT_PLAYER_TARGET } from "shared/constants";
import { isTveyesExpired } from "shared/helpers/date";
import { mentionKeywords } from "shared/helpers/mentions";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

import {
  ArchivedContentLabel,
  ExpiredContentNotice,
  FullBroadcastAnchor,
} from "./BroadcastContentMentionCard";

export default {
  name: "TVMentionCard",
  components: {
    AdvertisingValueWidget,
    BaseButton,
    BroadcastAudienceWidget,
    ImpactScoreWidget,
    LineClamp,
    MentionCard,
    MentionCardInsights,
    PlayerPopoutTVCardControl,
    PortalTarget,
    ReactScoreWidget,
    SentimentWidget,
    StreemTooltip,
    SyndicationsPopper,
    TimeAgo,
    UniversalPlayerControlWidget,
    ExpiredContentNotice,
    ArchivedContentLabel,
    FullBroadcastAnchor,
  },
  props: {
    ...propsExcept(["listTitle", "keywords"]),
  },
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { mentionClicked } = useMention(mentionProps, context);

    const mentionCountsStore = useMentionCountsStore();
    const { mentionCount } = storeToRefs(mentionCountsStore);

    const universalPlayerStore = useUniversalPlayerStore();

    const { portalName, hasPortalContent, handlePortalChange } =
      usePortalContent("tv-card");

    const {
      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsPlaying,
      playerList,
      playerIsDismissed,
    } = storeToRefs(universalPlayerStore);

    const {
      playerHide,
      setPlayerPopoutTarget,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,
    } = universalPlayerStore;

    return {
      mentionClicked,
      mentionCount,

      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsPlaying,
      playerList,
      playerIsDismissed,

      playerHide,
      setPlayerPopoutTarget,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,

      portalName,
      hasPortalContent,
      handlePortalChange,
    };
  },
  data() {
    return {
      fragments: [],
      clip: { media_url: "", start_time: 0 },
      showTVCardControl: true,
      isIntersecting: false,
      sentimentValue: null,
    };
  },
  computed: {
    isExpired() {
      return isTveyesExpired(this.mention);
    },
    isArchived() {
      return (
        Boolean(this.mention.tveyes_media_request) &&
        this.mention.tveyes_media_request.status !== "Cancelled"
      );
    },
    isTveyesContent() {
      return this.mention.tveyes_player_url?.length;
    },
    sentimentWidgetKey() {
      return `sentiment-widget-${this.mention.id}-${this.sentimentValue}`;
    },
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (this.mention.audience) {
        widgets.push("BroadcastAudienceWidget");
      }

      if (
        this.mention.advertising_value &&
        this.$features.has("advertising_value_mentions")
      ) {
        widgets.push("AdvertisingValueWidget");
      }

      if (this.canShowReactScore) widgets.push("ReactScoreWidget");

      if (this.mention.impact_score?.grade) {
        widgets.push("ImpactScoreWidget");
      }

      return widgets;
    },
    listTitle() {
      const mentionTitle = this.mention.program_airing
        ? this.mention.program_airing.name
        : this.mention.source.name;

      return `${mentionTitle} ${this.mention.source.location}`;
    },
    medium() {
      return this.mention.medium?.toLowerCase();
    },
    keywords() {
      return mentionKeywords(this.mention);
    },
    hasTranscript() {
      if (this.isTveyesContent || this.mention.datahub) {
        return false;
      }

      return ["tv_super", "tv_caption"].includes(this.mention.type);
    },
    firstMentionTime() {
      const index = this.fragments.findIndex(
        (fragment) => fragment.text.search(this.keywords[0]) !== -1
      );

      return index !== -1
        ? this.fragments[index].timestamp
        : this.mention.start_time;
    },
    fragmentParams() {
      return {
        after: this.mention.start_time,
        before: this.mention.end_time + NEXT_CLIP_OFFSET,
        limit: 20,
      };
    },
    inPlaylist() {
      return this.playerList.some((listItem) => listItem.id === this.clip.id);
    },
    isReportsRoute() {
      return this.$route.path.startsWith("/reports");
    },
  },
  watch: {
    "mention.sentiment_ratings": {
      deep: true,
      handler(options) {
        this.sentimentValue = options[0]?.sentiment;
      },
    },
  },
  beforeUnmount() {
    if (
      this.isPopoutPlayerPlayingThisClip() &&
      this.playerOpen &&
      this.$isDesktop
    ) {
      this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);

      this.$track("Playing the clip in the popout player", {
        type: "tv",
      });
    }
  },
  mounted() {
    this.loadMedia();

    this.mention.sentiment_ratings ??= [];
  },
  methods: {
    openViewTranscriptModal() {
      this.$track("Opened View Transcript Modal", {
        id: this.mention.id,
        type: this.mention.type,
        streamId: this.stream?.id,
      });

      this.$modals.open("ViewTranscriptModal", {
        props: {
          mention: this.mention,
          keywords: this.keywords,
        },
      });
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    loadClips() {
      // fetching the 30-s clip starting between 30 seconds excluded before first
      // mention  until first mention time included
      return this.$streemApiV1.get(
        `tv_channels/${this.mention.source.id}/clips`,
        {
          params: {
            after: this.mention.start_time - 15,
            before: this.mention.end_time + 15,
            limit: 3,
          },
        }
      );
    },
    loadFragments(fragmentParams) {
      const type =
        this.mention.type === "tv_logo_appearance"
          ? "tv_caption"
          : this.mention.type;

      return this.$streemApiV1.get(
        `tv_channels/${this.mention.source.id}/${type}_fragments`,
        {
          params: fragmentParams,
        }
      );
    },
    async loadMedia() {
      if (this.mention.datahub) {
        return;
      }

      const fragments = (await this.loadFragments(this.fragmentParams)).data;
      this.fragments = fragments || [];

      const clips = (await this.loadClips()).data;

      if (clips.length) {
        const clipContainingFirstMention = clips.filter(
          (clip) =>
            clip.start_time <= this.firstMentionTime &&
            clip.end_time >= this.firstMentionTime
        );

        this.clip = clipContainingFirstMention[0] || clips[0];

        this.clip = {
          ...this.clip,
          mentionId: this.mention.id,
          program_airing: this.mention.program_airing,
          source: this.mention.source,
          type: this.mention.type,
          excerpts: this.mention.excerpts,
          keywords: this.mention.keywords,
        };
      }
    },
    handleIntersection(el) {
      this.isIntersecting = el.isIntersecting;

      if (this.playerClosed()) {
        this.showTVCardControl = true;

        return;
      }

      if (!this.playerOpen) this.playerShow();
      this.setPlayerTarget();
    },
    playInPopout() {
      this.showTVCardControl = false;
      if (!this.inPlaylist) this.playerInsertClip(this.clip);
      if (!this.playerOpen) this.playerShow();

      this.$nextTick(() => {
        this.playerPopoutRef.playNow(this.clip);
      });

      this.setPlayerTarget();
      this.setPlayerDismissed(false);
    },
    setPlayerTarget() {
      if (this.playInCard()) {
        this.setPlayerPopoutTarget(this.portalName);
        this.showTVCardControl = false;

        this.$track("Playing the clip in the card", {
          type: "tv",
        });
      } else {
        this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);
        this.showTVCardControl = true;

        this.$track("Playing the clip in the popout player", {
          type: "tv",
        });
      }
    },
    playInCard() {
      return (
        !this.isReportsRoute &&
        ((this.isIntersecting && !this.isListView()) || this.$isMobile)
      );
    },
    isPopoutPlayerPlayingThisClip() {
      return (
        this.playerInitialClip &&
        this.clip &&
        this.playerInitialClip.id === this.clip.id
      );
    },
    isListView() {
      return this.options?.list;
    },
    playerClosed() {
      return (
        !this.isPopoutPlayerPlayingThisClip() ||
        this.playerIsDismissed ||
        this.alwaysPlayInPopout
      );
    },
    syndicationClicked(syndication) {
      this.mentionClicked({ ...syndication, type: this.mention.type });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-caption {
  padding-left: 20px;
  border-radius: 4px;
}
</style>
