import { MsAuthPlugin } from "@jacksongross/capacitor-plugin-msauth";
import { storeToRefs } from "pinia";

import { HOUR_IN_MILLISECONDS } from "shared/constants";
import type { AppConfig } from "shared/services/appConfig/types";
import Poller from "shared/services/polling/Poller";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

function getMsalConfig() {
  const globalStore = useGlobalStore();
  const { appConfig } = storeToRefs(globalStore);

  const { microsoftClientId } = <AppConfig>appConfig.value;

  return {
    clientId: microsoftClientId,
    scopes: ["User.Read", "email"],
    keyHash: "xSLzBBuLJunOQPB89rtzM54FXx4=",
  };
}

export const loginWithMicrosoft = async () => {
  const result = await MsAuthPlugin.login(getMsalConfig());

  return result.idToken;
};

export const refreshMicrosoftLogin = async () => {
  try {
    const idToken = await loginWithMicrosoft();
    await useUserStore().refreshMicrosoftSsoCallback(idToken);
  } catch {
    useUserStore().logout();
  }
};

export const signOutMicrosoft = () =>
  MsAuthPlugin.logout(getMsalConfig()).catch(() => {});

export const MicrosoftLoginPoller = new Poller({
  callback: refreshMicrosoftLogin,
  interval: HOUR_IN_MILLISECONDS,
});
