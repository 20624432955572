<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-intersection="handleIntersection"
    class="full-width"
  >
    <MentionCard
      :action-sheet-component="actionSheetComponent"
      :always-show-keywords="alwaysShowKeywords"
      class="pointer"
      :list-title="mention.title"
      :logo="mention.source ? mention.source.logo_url : null"
      :mention="mention"
      :mentions="mentions"
      :no-highlighting="noHighlighting"
      :options="options"
      :selected="selected"
      :show-checkbox="showCheckbox"
      :stream="stream"
      :title="mention.title"
      @click="mentionClicked($event)"
      @mention-removed="removeMention"
      @mention-selected="$emit('mention-selected', $event)"
    >
      <template #header>
        <span class="alternate">
          <LineClamp
            :lines="3"
            tag="span"
            @click="handleClick"
          >
            <span class="tw-text-sm tw-text-denim-900 hover:tw-font-bold">
              {{ mention.source.name }}
            </span>

            <template #after="{ clamped }">
              <StreemTooltip v-if="clamped">{{ mention.title }}</StreemTooltip>
            </template>
          </LineClamp>
        </span>

        <span class="softer tw-text-sm tw-leading-4">
          <TimeAgo :date="mention.timestamp" />

          <span v-if="hasDuration">&nbsp;· {{ formattedPodcastDuration }}</span>
        </span>
      </template>

      <slot />

      <template #body>
        <div
          class="tw-flex tw-flex-col"
          @click.stop
        >
          <div
            v-if="!playsOnTveyes"
            class="tw-mb-4"
          >
            <UniversalPlayerControlWidget
              v-if="!hasPortalContent"
              :clip="clip"
              new-design
              :start-time="mentionStartTime"
              @play="playInPopout"
            />
            <div @click.stop>
              <PortalTarget
                :name="portalName"
                @change="handlePortalChange"
              />
            </div>
          </div>

          <MentionExcerpts
            :always-show-keywords="playsOnTveyes"
            expandable
            :mention="mention"
            :no-highlighting="noHighlighting"
          />

          <PodcastMentionCardFragmentsDropdown
            v-if="fragmentsMatchingKeywords.length > 1"
            :fragments="fragmentsMatchingKeywords"
            :mention="mention"
            @click.stop
            @fragment-clicked="updatePlayerStart"
          />
        </div>
      </template>

      <template #afterBody>
        <MentionCardInsights
          :visible-widgets="visibleWidgets"
          :widget-keys="[sentimentWidgetKey]"
          @click.stop.prevent
        >
          <template #components>
            <SentimentWidget
              :key="sentimentWidgetKey"
              dense
              :mention="mention"
              :stream="stream"
            />

            <ReactScoreWidget
              dense
              :is-parent-visible="isIntersecting"
              :mention="mention"
            />
          </template>
        </MentionCardInsights>
      </template>

      <template #list-actions>
        <UniversalPlayerControlWidget
          v-if="podcastEpisodeFragments.length"
          class="play-button"
          :clip="clip"
          :start-time="mentionStartTime"
          @play="playInPopout"
        />
      </template>
    </MentionCard>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";
import { computed } from "vue";

import { LineClamp, StreemTooltip } from "shared/components/base";
import { MentionCard, props } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import MentionExcerpts from "shared/components/MentionExcerpts.vue";
import { UniversalPlayerControlWidget } from "shared/components/players/UniversalPlayerControlWidget";
import PodcastMentionCardFragmentsDropdown from "shared/components/PodcastMentionCardFragmentsDropdown.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import useMention from "shared/composables/useMention";
import usePodcastPlayer from "shared/composables/usePodcastPlayer";
import { usePortalContent } from "shared/composables/usePortalContent";
import { toSeconds } from "shared/helpers/date";
import { openExternalUrlForMention } from "shared/helpers/external";
import { mentionKeywords } from "shared/helpers/mentions";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "PodcastMentionCard",
  components: {
    LineClamp,
    StreemTooltip,
    PortalTarget,
    MentionCard,
    MentionExcerpts,
    UniversalPlayerControlWidget,
    PodcastMentionCardFragmentsDropdown,
    SentimentWidget,
    ReactScoreWidget,
    MentionCardInsights,
  },
  provide() {
    return {
      podcastEpisodeDuration: computed(() => this.podcastDuration),
    };
  },
  props,
  emits: ["mention-selected", "mention-removed", "click"],
  setup(mentionProps, context) {
    const universalPlayerStore = useUniversalPlayerStore();

    const { playerOpen, playerIsDismissed } = storeToRefs(universalPlayerStore);

    const { playerShow, universalPlayerRef } = universalPlayerStore;

    const { mentionClicked, playsOnTveyes } = useMention(mentionProps, context);

    const { portalName, hasPortalContent, handlePortalChange } =
      usePortalContent("podcast-card");

    const {
      podcastStartTimestamp,
      podcastDuration,
      formattedPodcastDuration,
      hasDuration,
      podcastEpisodeFragments,
      clip,
      loadClipInformation,
      isPopoutPlayerPlayingThisClip,
      setPlayerTarget,
      launchPlayer,
    } = usePodcastPlayer(mentionProps, context, portalName);

    return {
      playerShow,
      universalPlayerRef,

      playerOpen,
      playerIsDismissed,

      playsOnTveyes,
      mentionClicked,

      podcastStartTimestamp,
      podcastDuration,
      formattedPodcastDuration,
      hasDuration,
      podcastEpisodeFragments,
      clip,
      loadClipInformation,
      isPopoutPlayerPlayingThisClip,
      setPlayerTarget,
      launchPlayer,

      portalName,
      hasPortalContent,
      handlePortalChange,
    };
  },
  data() {
    return {
      selectedFragment: null,
      isIntersecting: false,
      sentimentValue: null,
    };
  },
  computed: {
    sentimentWidgetKey() {
      return `sentiment-widget-${this.mention.id}-${this.sentimentValue}`;
    },
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (this.canShowReactScore) widgets.push("ReactScoreWidget");

      return widgets;
    },
    fragmentsMatchingKeywords() {
      const keywordsMentioned = mentionKeywords(this.mention);

      const fragmentMatchRegex = new RegExp(
        `\\b(${keywordsMentioned.join("|")})\\b`
      );

      const fragmentObjects = [];

      this.podcastEpisodeFragments.forEach((fragment) => {
        const match = fragmentMatchRegex.exec(fragment.text);

        if (match) {
          fragmentObjects.push({
            ...fragment,
            keyword: match[0],
          });
        }
      });

      return fragmentObjects;
    },
    mentionStartTime() {
      const fragment = this.selectedFragment;

      return fragment
        ? this.podcastStartTimestamp + toSeconds(fragment.startTimeMs)
        : this.podcastStartTimestamp;
    },
  },
  watch: {
    "mention.sentiment_ratings": {
      deep: true,
      handler(options) {
        this.sentimentValue = options[0]?.sentiment;
      },
    },
  },
  mounted() {
    this.mention.sentiment_ratings ??= [];
  },
  methods: {
    updatePlayerStart(fragment) {
      this.selectedFragment = fragment;

      const startTime = this.mentionStartTime - this.clip.start_time;

      this.universalPlayerRef.currentTime = startTime;
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    handleIntersection({ isIntersecting }) {
      this.isIntersecting = isIntersecting;

      if (isIntersecting) {
        this.loadClipInformation();
      }

      if (this.playerClosed()) return;
      if (!this.playerOpen) this.playerShow();
      this.setPlayerTarget(this.playInCard());
    },
    playInPopout() {
      this.launchPlayer(this.playInCard());
    },
    playInCard() {
      return (
        (this.isIntersecting &&
          !this.isListView() &&
          !this.alwaysPlayInPopout) ||
        this.$isMobile
      );
    },
    isListView() {
      return this.options?.list;
    },
    playerClosed() {
      return !this.isPopoutPlayerPlayingThisClip() || this.playerIsDismissed;
    },
    handleClick() {
      if (this.playsOnTveyes) {
        return;
      }

      openExternalUrlForMention(this.mention);
    },
  },
};
</script>
