<!-- eslint-disable vue/no-v-html -->
<template>
  <MentionCard
    ref="mentionCard"
    v-intersection="recordView"
    :action-sheet-component="actionSheetComponent"
    :always-show-keywords="alwaysShowKeywords"
    card-title-class="hover:tw-underline"
    :class="mentionCardClass"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :no-highlighting="noHighlighting"
    :options="options"
    :selected="selected"
    :show-checkbox="showCheckbox"
    :stream="stream"
    :title="translatedTitle"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #header>
      <SyndicationsPopper
        v-if="mention?.syndications?.length"
        v-slot="{ toggleOpen }"
        :mention="mention"
        :parent-el-ref="$refs.mentionCard?.$el"
        :stream="stream"
        @syndication-clicked="mentionClicked($event)"
      >
        <BaseButton
          class="hover:tw-font-bold"
          new-design
          plain
          @click.stop.prevent="toggleOpen"
        >
          <LineClamp
            class="tw-text-sm tw-text-denim-900"
            :lines="1"
            tag="span"
          >
            {{ mention.source.name }}

            <template #after="{ clamped }">
              <StreemTooltip v-if="clamped">{{
                mention.source.name
              }}</StreemTooltip>
            </template>
          </LineClamp>

          <span class="tw-text-sm tw-text-denim-900"
            >+{{ mention.syndications.length }}</span
          >
        </BaseButton>
      </SyndicationsPopper>

      <LineClamp
        v-else
        class="alternate tw-break-all tw-text-sm tw-text-denim-900 hover:tw-font-bold"
        :lines="1"
        tag="span"
      >
        {{ mention.source.name }}

        <template #after="{ clamped }">
          <StreemTooltip v-if="clamped">{{
            mention.source.name
          }}</StreemTooltip>
        </template>
      </LineClamp>

      <span class="softer tw-text-sm tw-leading-4">
        <TimeAgo
          :date="mention.timestamp"
          :timezone="mention.source.time_zone"
        />
        <AuthorWidget
          v-if="mention.author"
          :disable-click="options.disableAuthorClick"
          :mention="mention"
        />
        <span class="inline-block">
          {{
            $t("print_mention_card.word_count", {
              wordCount: mention.word_count,
              pageNumbers: formatPageNumbers(mention.page_numbers, true),
            })
          }}
        </span>
      </span>
    </template>

    <slot />

    <template #afterBody>
      <MentionCardInsights
        :visible-widgets="visibleWidgets"
        :widget-keys="[sentimentWidgetKey]"
        @click.stop.prevent
      >
        <template #components>
          <PotentialAudienceReachWidget
            dense
            :mention="mention"
          />

          <AdvertisingValueWidget
            dense
            :mention="mention"
          />

          <SentimentWidget
            :key="sentimentWidgetKey"
            dense
            :mention="mention"
            :stream="stream"
          />

          <ReactScoreWidget
            dense
            :is-parent-visible="isVisible"
            :mention="mention"
          />

          <ImpactScoreWidget
            dense
            :impact-score="mention.impact_score"
          />
        </template>
      </MentionCardInsights>

      <MentionTranslationLanguage
        v-if="showTranslationLanguage"
        data-testid="mention-translation-language"
        :mention="mention"
      />
    </template>

    <template #after>
      <!-- Related Online Article -->
      <div v-if="canShowRelatedArticle">
        <RelatedMentionCard
          :action-sheet-component="actionSheetComponent"
          data-testid="related-mention-article"
          :mention="mention.related_article"
          :stream="stream"
          @mention:click="mentionClicked($event)"
          @syndication:click="mentionClicked($event)"
        >
          {{ $t("print_mention_card.view_online_version") }}
        </RelatedMentionCard>
      </div>
    </template>
  </MentionCard>
</template>

<script>
import { storeToRefs } from "pinia";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import AuthorWidget from "shared/components/AuthorWidget.vue";
import { BaseButton, LineClamp, StreemTooltip } from "shared/components/base";
import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import ImpactScoreWidget from "shared/components/ImpactScoreWidget.vue";
import { MentionCard, props } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import MentionTranslationLanguage from "shared/components/MentionTranslationLanguage.vue";
import PotentialAudienceReachWidget from "shared/components/PotentialAudienceReachWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import { RelatedMentionCard } from "shared/components/RelatedMentionCard";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import { SyndicationsPopper } from "shared/components/SyndicationsPopper";
import useMention from "shared/composables/useMention";
import { formatPageNumbers } from "shared/helpers/array";
import { useUserStore } from "shared/stores/user";

export default {
  name: "PrintMentionCard",
  components: {
    StreemTooltip,
    LineClamp,
    AdvertisingValueWidget,
    AuthorWidget,
    BaseButton,
    MentionCard,
    MentionCardInsights,
    MentionTranslationLanguage,
    PotentialAudienceReachWidget,
    ReactScoreWidget,
    ImpactScoreWidget,
    RelatedMentionCard,
    SentimentWidget,
    SyndicationsPopper,
    TimeAgo,
  },
  props,
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { isMentionNotClickable, mentionClicked } = useMention(
      mentionProps,
      context
    );

    const userStore = useUserStore();
    const { currentUser, isAdminUser } = storeToRefs(userStore);

    return {
      mentionClicked,
      isMentionNotClickable,
      currentUser,
      isAdminUser,
    };
  },
  data() {
    return {
      isVisible: false,
      hasRecorded: false,
      sentimentValue: null,
    };
  },
  computed: {
    sentimentWidgetKey() {
      return `sentiment-widget-${this.mention.id}-${this.sentimentValue}`;
    },
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (
        this.mention.advertising_value &&
        this.$features.has("advertising_value_mentions")
      ) {
        widgets.push("AdvertisingValueWidget");
      }

      if (this.mention.audience) widgets.push("PotentialAudienceReachWidget");
      if (this.canShowReactScore) widgets.push("ReactScoreWidget");

      if (this.mention.impact_score?.grade) {
        widgets.push("ImpactScoreWidget");
      }

      return widgets;
    },
    translatedTitle() {
      return this.mention.translated_title || this.mention.title;
    },
    canShowRelatedArticle() {
      return (
        this.$features.has("read_online_article") &&
        this.mention.related_article
      );
    },
    showTranslationLanguage() {
      return this.mention?.original_language !== "English";
    },
    mentionCardClass() {
      if (this.isMentionNotClickable(this.mention)) return null;

      return "pointer";
    },
  },
  watch: {
    "mention.sentiment_ratings": {
      deep: true,
      handler(options) {
        this.sentimentValue = options[0]?.sentiment;
      },
    },
  },
  mounted() {
    this.mention.sentiment_ratings ??= [];
  },
  methods: {
    formatPageNumbers,
    recordView({ isIntersecting }) {
      this.isVisible = isIntersecting;

      if (isIntersecting && this.$route.name !== "curated-email-report") {
        setTimeout(() => {
          if (this.isVisible && !this.isAdminUser && !this.hasRecorded) {
            this.hasRecorded = true;

            this.$track("Saw Paper Article", {
              userId: this.currentUser.id,
              paperArticleId: this.mention.id,
              sourceId: this.mention.source.id,
            });
          }
        }, 300);
      }
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
  },
};
</script>

<style lang="scss" scoped>
.after-body {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>
