import { constantize } from "shared/helpers/string";
import type { SourceGroup } from "shared/types/mentions";

import type { MediumField } from "./media";
import { getSources } from "./media";

type Filter = {
  action: string;
  id?: string | number;
  name?: string;
  scope: string;
  target_id: string | number;
  target_type: string;
};

interface SourceTopic {
  id: number;
  name: string;
}

export interface Source {
  id: number;
  type: string;
  medium: MediumField;
  name: string;
  location: string;
  source_group_and_associated_ids?: string[] | number[];
  target_type?: string;
  filters?: Filter[];
  source_group_name?: string;
  sector?: string;
  category?: string;
  topics?: SourceTopic[];
  source_group?: SourceGroup;
  logo_url?: string;
}

function isSourceGroupMediumLocation(item: Source): boolean {
  return (
    item.type === "source_group_medium_location" ||
    item.target_type === "SourceGroupMediumLocation"
  );
}

function includedSources(streamSources: Source[]): Source[] {
  return streamSources.map((source) => ({
    ...source,
    action: "include",
    target_type: source.target_type || constantize(source.type),
    target_id: source.id,
  }));
}

function excludedSources(streamSources: Source[]): Source[] {
  return streamSources.map((source) => ({
    ...source,
    action: "exclude",
    target_type: source.target_type || constantize(source.type),
    target_id: source.id,
  }));
}

function sourceGroupFilters(
  originalFilters: Filter[],
  includedSourcesParams: Source[],
  excludedSourcesParams: Source[]
): Filter[] {
  const processSourceGroups = (
    streamSources: Source[],
    action: string
  ): Filter[] =>
    streamSources.flatMap((source) => {
      if (!isSourceGroupMediumLocation(source)) {
        return [];
      }

      return (source.source_group_and_associated_ids || []).flatMap((id) =>
        getSources().map(({ field }) => ({
          target_type: "SourceGroup",
          scope: field,
          target_id: id,
          action,
        }))
      );
    });

  const filters: Filter[] = (originalFilters || []).filter(
    (filter) => filter.target_type !== "SourceGroup"
  );

  filters.push(
    ...processSourceGroups(includedSourcesParams, "include"),
    ...processSourceGroups(excludedSourcesParams, "exclude")
  );

  return filters;
}

function sourceKeysFor(sources: Source[]): string[] {
  return sources.flatMap((source) => {
    if (!isSourceGroupMediumLocation(source)) {
      return `${source.medium.toLowerCase()}_${source.id}`;
    }

    return [];
  });
}

function sourceGroupKeys(sources: Source[]): string[] {
  return sources.flatMap((source) => {
    if (!isSourceGroupMediumLocation(source)) return [];

    return (
      source.source_group_and_associated_ids?.flatMap((id) =>
        getSources().map(({ field }) => `${field}_${id}`)
      ) || []
    );
  });
}

export {
  excludedSources,
  includedSources,
  isSourceGroupMediumLocation,
  sourceGroupFilters,
  sourceGroupKeys,
  sourceKeysFor,
};
