import { defineAsyncComponent } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { streamTypes } from "shared/constants";
import applyThemeConfig from "shared/helpers/applyThemeConfig";
import { getCssVariableValue } from "shared/helpers/dom";
import transformKeys from "shared/helpers/transformKeys";

export default applyThemeConfig([
  {
    name: "login",
    path: "/sign-in",
    alias: "/login",
    component: () => import("pages/Login.vue"),
    meta: {
      // This var means a not logged user can access the route. Used by the navigation guard
      freeAccess: true,
      get statusBarColor() {
        return getCssVariableValue("--s-menu-default");
      },
      statusBarStyle: "Light",
    },
    props: (route) => ({
      authToken: route.query.auth_token,
      redirect: route.query.redirect,
      resetKey: route.query.reset_key,
    }),
  },
  {
    path: "/oauth",
    component: () => import("shared/pages/OauthLogin.vue"),
    props: (route) => ({
      ...route.params,
      ...transformKeys(route.query, "camelCase"),
    }),
    meta: {
      public: true,
      freeAccess: true,
    },
  },

  {
    path: "/",
    component: () => import("layouts/DefaultLayout.vue"),
    children: [
      {
        name: "mention-detail",
        path: "/mention-detail/:mentionId/:streamId/:mentionType",
        alias: "/mention-detail/:mentionId/:streamId/:mentionType",
        component: () => import("pages/MentionDetailPage"),
        props: (route) => ({
          mentionId: route.params.mentionId,
          streamId: route.params.streamId,
          mentionType: route.params.mentionType,
        }),
        meta: {
          pushed: true,
          buttons: [
            {
              name: "show-action-sheet-mention-detail",
              iconType: "material",
              icon: "more_vert",
              label: () =>
                getLocaleText("routes.buttons.action_sheet_mention_details"),
            },
          ],
        },
      },
      {
        path: "",
        name: "launchpad",
        component: () => import("pages/MainPage.vue"),
        meta: {
          get statusBarColor() {
            return getCssVariableValue("--s-menu-default");
          },
          statusBarStyle: "Light",
        },
      },
      {
        path: "search",
        name: "search",
        meta: {
          hideIntercom: true,
        },
      },
      {
        path: "paper_articles/:id/:streamId?",
        name: "paper_article",
        component: () =>
          import("shared/components/streams/preview/PaperArticlePreview.vue"),
        props: (route) => ({
          id: route.params.id,
          streamId: route.params.streamId || "",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.print_item"),
          pushed: true,
          buttons: [
            {
              name: "show-action-sheet-paper-article",
              iconType: "material",
              icon: "more_vert",
              label: () =>
                getLocaleText("routes.buttons.action_sheet_paper_article"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "articles/:id/:streamId?",
        name: "article",
        component: () => import("src/components/MentionDetailArticle.vue"),
        props: (route) => ({
          id: route.params.id,
          streamId: route.params.streamId || "",
          articleType: route.meta.articleType,
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.online_item"),
          pushed: true,
          articleType: "article",
          buttons: [
            {
              name: "show-action-sheet-article",
              iconType: "material",
              icon: "more_vert",
              label: () => getLocaleText("routes.buttons.action_sheet_article"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "magazine_articles/:id/:streamId?",
        name: "magazine_article",
        component: () =>
          import(
            "shared/components/streams/preview/MagazineArticlePreview.vue"
          ),
        props: (route) => ({
          id: route.params.id,
          streamId: route.params.streamId || "",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.magazine_item"),
          pushed: true,
          buttons: [
            {
              name: "show-action-sheet-magazine-article",
              iconType: "material",
              icon: "more_vert",
              label: () =>
                getLocaleText("routes.buttons.action_sheet_magazine_article"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "tv_captions/:id/:streamId?",
        name: "tv_caption",
        component: () =>
          import("shared/components/streams/preview/TvPreview.vue"),
        props: (route) => ({
          id: route.params.id,
          tvType: route.meta.tvType,
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.media_item"),
          pushed: true,
          tvType: "tv_caption",
          buttons: [
            {
              name: "show-action-sheet-caption",
              iconType: "material",
              icon: "more_vert",
              label: () => getLocaleText("routes.buttons.action_sheet_caption"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "tv_supers/:id/:streamId?",
        name: "tv_super",
        component: () =>
          import("shared/components/streams/preview/TvPreview.vue"),
        props: (route) => ({
          id: route.params.id,
          streamId: route.params.streamId || "",
          tvType: route.meta.tvType,
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.media_item"),
          pushed: true,
          tvType: "tv_super",
          buttons: [
            {
              name: "show-action-sheet-caption",
              iconType: "material",
              icon: "more_vert",
              label: () =>
                getLocaleText("routes.buttons.action_sheet_tv_super"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "tv_logo_appearances/:id/:streamId?",
        name: "tv_logo_appearance",
        component: () =>
          import("shared/components/streams/preview/TvPreview.vue"),
        props: (route) => ({
          id: route.params.id,
          streamId: route.params.streamId || "",
          tvType: route.meta.tvType,
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.media_item"),
          pushed: true,
          tvType: "tv_logo_appearance",
          buttons: [
            {
              name: "show-action-sheet-caption",
              iconType: "material",
              icon: "more_vert",
              label: () =>
                getLocaleText("routes.buttons.action_sheet_tv_logo_appearance"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "radio_clips/:id/:streamId?",
        name: "radio_clip",
        component: () => import("src/components/MentionDetailRadio.vue"),
        props: (route) => ({
          id: route.params.id,
          streamId: route.params.streamId || "",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.media_item"),
          pushed: true,
          buttons: [
            {
              name: "show-action-sheet-radio-clip",
              iconType: "material",
              icon: "more_vert",
              label: () =>
                getLocaleText("routes.buttons.action_sheet_radio_clip"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "podcast_episodes/:id/:streamId?",
        name: "podcast_episode",
        component: () => import("src/components/MentionDetailPodcast.vue"),
        props: (route) => ({
          id: route.params.id,
          streamId: route.params.streamId || "",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          title: () => getLocaleText("routes.media_item"),
          pushed: true,
          buttons: [
            {
              name: "show-action-sheet-podcast-episode",
              iconType: "material",
              icon: "more_vert",
              label: () =>
                getLocaleText("routes.buttons.action_sheet_podcast_episode"),
            },
          ],
          hideIntercom: true,
        },
      },
      {
        path: "tweets/",
        name: "tweet",
        component: () => import("src/components/MentionDetailSocial.vue"),
        props: (route) => ({
          socialMention: route.params.tweetMention,
          socialType: "tweet",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "tweets/:id",
        name: "tweet_preview",
        component: () =>
          import("shared/components/streams/preview/TweetPreview.vue"),
        props: (route) => ({
          id: route.params.id,
          product: route.query.product,
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "facebook_posts/",
        name: "facebook_post",
        component: () => import("src/components/MentionDetailSocial.vue"),
        props: (route) => ({
          socialMention: route.params.tweetMention,
          socialType: "facebook_post",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "instagram_posts/",
        name: "instagram_post",
        component: () => import("src/components/MentionDetailSocial.vue"),
        props: (route) => ({
          socialMention: route.params.tweetMention,
          socialType: "instagram_post",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "reddit_posts/",
        name: "reddit_post",
        component: () => import("src/components/MentionDetailSocial.vue"),
        props: (route) => ({
          socialMention: route.params.tweetMention,
          socialType: "reddit_post",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "blog_posts/",
        name: "blog_post",
        component: () => import("src/components/MentionDetailSocial.vue"),
        props: (route) => ({
          socialMention: route.params.tweetMention,
          socialType: "blog_post",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "forum_posts/",
        name: "forum_post",
        component: () => import("src/components/MentionDetailSocial.vue"),
        props: (route) => ({
          socialMention: route.params.tweetMention,
          socialType: "forum_post",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "youtube_videos/",
        name: "youtube_video",
        component: () => import("src/components/MentionDetailSocial.vue"),
        props: (route) => ({
          socialMention: route.params.tweetMention,
          socialType: "youtube_video",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "front-pages",
        name: "front-pages",
        component: () => import("pages/Frontpages.vue"),
        meta: {
          buttons: [
            {
              name: "set-tracked-publishers",
              icon: "ion-md-settings",
              color: "#b2b2b2",
              label: () =>
                getLocaleText("routes.buttons.set_tracked_publishers"),
            },
          ],
          topComponent: defineAsyncComponent({
            loader: () => import("src/components/MenuDropdown.vue"),
          }),
        },
      },
      {
        path: "daily-highlights",
        name: "daily-highlights",
        component: () => import("pages/DailyHighlightsPage.vue"),
        meta: {
          leftIcon: "ion-ios-arrow-back",
          title: () => getLocaleText("routes.daily_highlights"),
        },
      },
      {
        path: "daily-highlights/:id",
        component: () => import("pages/DailyHighlightDetailPage.vue"),
        props: true,
        meta: {
          pushed: true,
        },
      },
      {
        path: "media-and-transcripts",
        name: "media-and-transcripts",
        component: () => import("pages/MediaTranscriptRequests.vue"),
        meta: {
          title: () => getLocaleText("routes.media_and_transcripts"),
          fitScreen: true,
        },
      },
      {
        path: "streams",
        name: "streams",
        component: () => import("pages/Streams.vue"),
        meta: {
          title: () => getLocaleText("routes.mention_streams"),
          buttons: [
            {
              name: "add-mention-stream",
              icon: "icon icon-plus-squared",
              color: "#b2b2b2",
              feature: "add_stream",
              label: () => getLocaleText("routes.buttons.add_mention_stream"),
            },
          ],
        },
      },
      {
        path: "streams/:groupSlug/:streamSlug",
        component: () => import("pages/Stream.vue"),
        props: (route) => ({
          groupSlug: route.params.groupSlug,
          streamSlug: route.params.streamSlug,
        }),
        name: "stream",
        meta: {
          buttons: [
            {
              name: "filter",
              icon: "filter_alt",
              color: "#b2b2b2",
              size: 25,
              toggleable: true,
              iconType: "material",
              class: "bold",
              label: () => getLocaleText("routes.buttons.filter"),
            },
            {
              name: "edit-mention-stream",
              icon: "ion-md-settings",
              color: "#b2b2b2",
              size: 25,
              label: () => getLocaleText("routes.buttons.edit_mention_stream"),
            },
          ],
        },
      },
      {
        name: "social",
        path: "social",
        component: () => import("pages/Social/Social.vue"),
        meta: {
          product: "social",
        },
        children: [
          {
            path: "stream-notifications/:notificationId?",
            name: "socialStreamNotificationsEdit",
            component: () => import("shared/pages/StreamNotificationsEdit.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.notifications"),
              showNavButtons: true,
            },
          },
          {
            path: "monitor/:groupSlug/:streamSlug",
            component: () => import("pages/SocialStreamPage.vue"),
            props: (route) => ({
              groupSlug: route.params.groupSlug,
              streamSlug: route.params.streamSlug,
            }),
            name: "social-monitor",
            meta: {
              buttons: [
                {
                  name: "filter",
                  icon: "filter_alt",
                  color: "#b2b2b2",
                  size: 25,
                  toggleable: true,
                  iconType: "material",
                  class: "bold",
                  label: () => getLocaleText("routes.buttons.filter"),
                },
                {
                  name: "edit-mention-stream",
                  icon: "ion-md-settings",
                  color: "#b2b2b2",
                  size: 25,
                  label: () =>
                    getLocaleText("routes.buttons.edit_social_stream"),
                },
              ],
            },
          },
          {
            path: "social-stream/new",
            name: "social-stream-new",
            component: () => import("src/pages/MentionsStreamBuilderPage.vue"),
            props: (route) => ({
              streamType: streamTypes.socialStream,
              ...route.params,
            }),
            meta: {
              title: () => getLocaleText("routes.add_social_stream"),
              showNavButtons: true,
              hasClose: true,
              fitScreen: true,
              hideIntercom: true,
            },
          },
          {
            path: "monitor/:groupSlug/:streamSlug/edit/notification/:notificationId?",
            name: "social-stream-notification-edit",
            component: () =>
              import("src/pages/Social/SocialStreamNotificationEdit.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.social_stream_notification"),
              fitScreen: true,
              buttons: [
                {
                  icon: "ion-md-close",
                  name: "close-mention-stream-notification",
                  size: 20,
                  label: () =>
                    getLocaleText(
                      "routes.buttons.close_mention_stream_notification"
                    ),
                },
              ],
            },
          },
          {
            path: "monitor/:groupSlug/:streamSlug/edit/:tab?",
            name: "social-stream-edit",
            component: () => import("src/pages/Social/SocialStreamEdit.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.edit_social_stream"),
              showNavButtons: true,
              fitScreen: true,
              hideIntercom: true,
              reuseComponent: true,
              buttons: [
                {
                  icon: "ion-md-close",
                  name: "close-mention-stream",
                  size: 20,
                  label: () =>
                    getLocaleText("routes.buttons.close_social_stream"),
                },
              ],
            },
          },
          {
            path: "social-stream/group/:groupSlug/edit",
            name: "social-stream-group-edit",
            component: () =>
              import("src/pages/Social/SocialStreamGroupEdit.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.stream_group_edit"),
              showNavButtons: true,
              hasClose: true,
              fitScreen: true,
              hideIntercom: true,
            },
          },
          {
            name: "social-monitor-shared",
            redirect: { name: "social-monitor" },
            path: "social-stream/shared",
          },
          {
            path: "social-bookmark-stream/:groupSlug/:streamSlug",
            name: "socialBookmarkStream",
            component: () => import("pages/BookmarkStream.vue"),
            props: (route) => ({
              ...route.params,
              isSocialStream: true,
            }),
            meta: {
              buttons: [
                {
                  name: "filter",
                  icon: "filter_alt",
                  color: "#b2b2b2",
                  size: 25,
                  toggleable: true,
                  iconType: "material",
                  label: () => getLocaleText("routes.buttons.filter"),
                },
                {
                  name: "edit-bookmark-stream",
                  icon: "ion-md-settings",
                  color: "#b2b2b2",
                  size: 25,
                  label: () =>
                    getLocaleText("routes.buttons.edit_bookmark_stream"),
                },
              ],
            },
          },
          {
            path: "social-bookmark-stream/:groupSlug/:streamSlug/edit",
            name: "social-bookmark-stream-edit",
            component: () => import("pages/BookmarkStreamEdit.vue"),
            props: (route) => ({
              ...route.params,
              isSocialStream: true,
            }),
            meta: {
              title: () => getLocaleText("routes.tagged_folder_edit"),
              showNavButtons: true,
              hasClose: true,
              fitScreen: true,
              hideIntercom: true,
            },
          },
        ],
      },
      {
        name: "shared-stream",
        redirect: { name: "stream" },
        path: "aezaeaz",
      },
      {
        path: "bookmark-stream/:groupSlug/:streamSlug/edit",
        name: "bookmark-stream-edit",
        component: () => import("pages/BookmarkStreamEdit.vue"),
        props: true,
        meta: {
          title: () => getLocaleText("routes.tagged_folder_edit"),
          showNavButtons: true,
          hasClose: true,
          fitScreen: true,
          hideIntercom: true,
        },
      },
      {
        path: "bookmark-stream/:groupSlug/:streamSlug",
        name: "bookmarkStream",
        component: () => import("pages/BookmarkStream.vue"),
        props: true,
        meta: {
          buttons: [
            {
              name: "filter",
              icon: "filter_alt",
              color: "#b2b2b2",
              size: 25,
              toggleable: true,
              iconType: "material",
              label: () => getLocaleText("routes.buttons.filter"),
            },
            {
              name: "edit-bookmark-stream",
              icon: "ion-md-settings",
              color: "#b2b2b2",
              size: 25,
              label: () => getLocaleText("routes.buttons.edit_bookmark_stream"),
            },
          ],
        },
      },
      {
        path: "alerts",
        name: "alerts",
        component: () => import("pages/Alerts.vue"),
        meta: {
          title: () => getLocaleText("routes.alerts"),
          pushed: true,
        },
      },
      {
        path: "mention-alert/:category/:mentionId/:streamId?",
        name: "mentionAlert",
        component: () => import("pages/MentionPreviewAlert.vue"),
        props: (route) => ({
          mentionId: route.params.mentionId,
          category: route.params.category,
          streamId: route.params.streamId || "",
          keywords: [].concat(route.query.keywords || []),
        }),
        meta: {
          pushed: true,
          hideIntercom: true,
        },
      },
      {
        path: "journalist",
        name: "journalist-view",
        component: () => import("pages/JournalistView.vue"),
        props: (route) => ({
          mention: JSON.parse(window.history.state.mention),
          author: route.query.author,
        }),
        meta: {
          title: "",
          hasClose: true,
          fitScreen: true,
        },
      },
      {
        path: "help",
        name: "help",
        component: () => import("shared/components/user/ContactMenu.vue"),
        meta: {
          title: () => getLocaleText("routes.help"),
        },
      },
      {
        path: "streams",
        component: () => import("src/pages/MentionStream/MentionStream.vue"),
        children: [
          {
            path: "new",
            name: "mention-stream-new",
            component: () => import("src/pages/MentionsStreamBuilderPage.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.mention_stream_create"),
              fitScreen: true,
              hasClose: true,
            },
          },
          {
            path: "group/:groupSlug/edit",
            name: "mention-stream-group-edit",
            component: () =>
              import("src/pages/MentionStream/MentionStreamGroupEdit.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.stream_group_edit"),
              fitScreen: true,
              hasClose: true,
            },
          },
          {
            path: "new/:groupSlug?",
            name: "mention-stream-new",
            component: () => import("src/pages/MentionsStreamBuilderPage.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.mention_stream_create"),
              fitScreen: true,
              hasClose: true,
            },
          },
          {
            path: ":groupSlug/:streamSlug/edit/notification/:notificationId?",
            name: "mention-stream-notification-edit",
            component: () =>
              import(
                "src/pages/MentionStream/MentionStreamNotificationEdit.vue"
              ),
            props: true,
            meta: {
              title: () => getLocaleText("routes.mention_stream_notification"),
              fitScreen: true,
              buttons: [
                {
                  icon: "ion-md-close",
                  name: "close-mention-stream-notification",
                  size: 20,
                  label: () =>
                    getLocaleText(
                      "routes.buttons.close_mention_stream_notification"
                    ),
                },
              ],
            },
          },
          {
            path: ":groupSlug/:streamSlug/edit/:tab?",
            name: "mention-stream-edit",
            component: () =>
              import("src/pages/MentionStream/MentionStreamEdit.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.mention_stream_edit"),
              fitScreen: true,
              reuseComponent: true,
              buttons: [
                {
                  icon: "ion-md-close",
                  name: "close-mention-stream",
                  size: 20,
                  label: () =>
                    getLocaleText("routes.buttons.close_mention_stream"),
                },
              ],
            },
          },
          {
            path: "",
            name: "mention-stream",
            component: () => import("src/pages/MentionsStreamBuilderPage.vue"),
            props: true,
            meta: {
              title: () => getLocaleText("routes.mention_stream_create"),
              fitScreen: true,
              hasClose: true,
            },
          },
        ],
      },
    ],
  },
]);
