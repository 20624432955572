<!-- eslint-disable vue/no-v-html -->
<template>
  <MentionCard
    :action-sheet-component="actionSheetComponent"
    :always-show-keywords="alwaysShowKeywords"
    card-title-class="hover:tw-underline"
    :class="mentionCardClass"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :no-highlighting="noHighlighting"
    :options="options"
    :selected="selected"
    :show-checkbox="showCheckbox"
    :stream="stream"
    :title="mention.title"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #header>
      <div class="tw-text-sm tw-text-denim-900 hover:tw-font-bold">
        <span
          v-if="mention.syndications && mention.syndications.length > 0"
          @click.stop="displaySyndication = !displaySyndication"
        >
          {{ mention.source.name }} + {{ mention.syndications.length }}
        </span>
        <span v-else>
          {{ mention.source.name }}
        </span>
      </div>

      <span class="softer tw-text-sm tw-leading-4">
        <TimeAgo
          :date="mention.timestamp"
          :timezone="mention.source.time_zone"
        />
        <AuthorWidget
          v-if="mention.author"
          :disable-click="options.disableAuthorClick"
          :mention="mention"
        />
        <span>
          {{
            $t("magazine_mention_card.word_count", {
              wordCount: mention.word_count,
              pageNumbers: formatPageNumbers(mention.page_numbers, true),
            })
          }}
        </span>
      </span>
    </template>

    <template #beforeBody>
      <div>
        <!-- Display syndications -->
        <div
          v-if="displaySyndication"
          class="publishers-list"
        >
          <a
            v-for="(syndicated, key) in mention.syndications"
            :key="key"
            class="no-shrink"
            @click.stop="syndicationClicked(syndicated)"
          >
            <span class="bold">
              {{ syndicated.source_name }}
            </span>
            <span
              class="muted pull-right syndication-time"
              style
            >
              {{ formatDate(syndicated.published_at, "h:mmaaa") }}
            </span>
          </a>
        </div>
      </div>
    </template>

    <template #afterBody>
      <MentionCardInsights
        :visible-widgets="visibleWidgets"
        :widget-keys="[sentimentWidgetKey]"
        @click.stop.prevent
      >
        <template #components>
          <PotentialAudienceReachWidget
            dense
            :mention="mention"
          />

          <AdvertisingValueWidget
            dense
            :mention="mention"
          />

          <SentimentWidget
            :key="sentimentWidgetKey"
            dense
            :mention="mention"
            :stream="stream"
          />

          <ImpactScoreWidget
            dense
            :impact-score="mention.impact_score"
          />

          <ReactScoreWidget
            dense
            is-parent-visible
            :mention="mention"
          />
        </template>
      </MentionCardInsights>
    </template>

    <slot />

    <template #after>
      <!-- Related Online Article -->
      <div v-if="canShowRelatedArticle">
        <RelatedMentionCard
          :action-sheet-component="actionSheetComponent"
          data-testid="related-mention-article"
          :mention="mention.related_article"
          :stream="stream"
          @mention:click="mentionClicked($event)"
          @syndication:click="mentionClicked($event)"
        >
          {{ $t("print_mention_card.view_online_version") }}
        </RelatedMentionCard>
      </div>
    </template>
  </MentionCard>
</template>

<script>
import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import AuthorWidget from "shared/components/AuthorWidget.vue";
import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import ImpactScoreWidget from "shared/components/ImpactScoreWidget.vue";
import { MentionCard, props } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import PotentialAudienceReachWidget from "shared/components/PotentialAudienceReachWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import { RelatedMentionCard } from "shared/components/RelatedMentionCard";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import useMention from "shared/composables/useMention";
import { formatPageNumbers } from "shared/helpers/array";
import { formatDate } from "shared/helpers/date";

export default {
  name: "MagazineMentionCard",
  components: {
    MentionCard,
    AuthorWidget,
    AdvertisingValueWidget,
    PotentialAudienceReachWidget,
    SentimentWidget,
    ReactScoreWidget,
    ImpactScoreWidget,
    MentionCardInsights,
    RelatedMentionCard,
    TimeAgo,
  },
  props,
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { isMentionNotClickable, mentionClicked } = useMention(
      mentionProps,
      context
    );

    return {
      isMentionNotClickable,
      mentionClicked,
    };
  },
  data() {
    return {
      displaySyndication: false,
      sentimentValue: null,
    };
  },
  computed: {
    sentimentWidgetKey() {
      return `sentiment-widget-${this.mention.id}-${this.sentimentValue}`;
    },
    mentionCardClass() {
      if (this.isMentionNotClickable(this.mention)) return null;

      return "pointer";
    },
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    canShowRelatedArticle() {
      return (
        this.$features.has("read_online_article") &&
        this.mention.related_article
      );
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (this.mention.audience) {
        widgets.push("PotentialAudienceReachWidget");
      }

      if (
        this.mention.advertising_value &&
        this.$features.has("advertising_value_mentions")
      ) {
        widgets.push("AdvertisingValueWidget");
      }

      if (this.canShowReactScore) widgets.push("ReactScoreWidget");

      if (this.mention.impact_score?.grade) {
        widgets.push("ImpactScoreWidget");
      }

      return widgets;
    },
  },
  watch: {
    "mention.sentiment_ratings": {
      deep: true,
      handler(options) {
        this.sentimentValue = options[0]?.sentiment;
      },
    },
  },
  mounted() {
    this.mention.sentiment_ratings ??= [];
  },
  methods: {
    formatDate,
    formatPageNumbers,
    syndicationClicked(syndication) {
      this.mentionClicked({ ...syndication, type: "article" });
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
  },
};
</script>
